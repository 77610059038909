.drawer {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 250px;
  background: white;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
  transform: translateX(-100%);
  transition: transform 0.3s ease;
  z-index: 1000;
}
.drawer.open {
  transform: translateX(0);
}
.drawer-content {
  padding: 1rem;
}
.close-button {
  background: none;
  border: none;
  font-size: 2rem;
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
}

.scrollbar::-webkit-scrollbar {
  width: 8px;
  height: 350px;
}

.scrollbar::-webkit-scrollbar-thumb {
  background-color: #e7e7e7;
  border-radius: 4px;
}

.scrollbar::-webkit-scrollbar-track {
  background-color: transparent;
}
